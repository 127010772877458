import React from "react";

export const ReportMainPage = ({
  pageRef,
  userName,
  date,
  club,
  overallScore,
  totalPages,
  speedScore,
  clubSpeed,
  efficiencyScore,
  consistencyScore,
  descriptionSections,
  swingsNumber,
  swingType,
}) => {
  return (
    <div
      ref={pageRef}
      style={{
        width: "800px",
        height: "1150px",
        margin: "20px auto",
        padding: "80px",
        backgroundColor: "#fff",
        color: "#333",
        fontSize: "11px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 style={{ fontSize: "16px" }}>Swing Score Report</h2>
        <img src="/images/Logo.svg" alt="logo" />
      </div>
      <div style={{ display: "flex", gap: "20px", padding: "20px 0" }}>
        <div>{userName}</div>
        <div>Date: {date}</div>
        <div>Club: {club}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* SVG as Background */}
          <img
            src="/images/pdf-report/star.svg"
            alt="Custom Border"
            style={{
              width: "90px", // Adjust size as needed
              height: "90px",
              position: "absolute",
              top: "15px",
              left: "-10px",
            }}
          />

          {/* Number Overlay */}
          <h1
            style={{
              fontSize: "36px",
              margin: "0",
              color: "#333",
              position: "absolute",
              width: "120px",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pointerEvents: "none", // Prevents interaction issues
            }}
          >
            {overallScore}
          </h1>

          <p style={{ margin: "0", color: "#777", marginTop: "130px" }}>
            Overall Score
          </p>
        </div>
        <div
          style={{
            display: "flex",
            gap: "40px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                {swingType}
              </div>
              <div style={{ color: "#6C737F" }}>Swing Type</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                {speedScore}
              </div>
              <div style={{ color: "#6C737F" }}>Speed Score</div>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                #{swingsNumber}
              </div>
              <div style={{ color: "#6C737F" }}>Swings Taken</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                {efficiencyScore}
              </div>
              <div style={{ color: "#6C737F" }}>Efficiency Score</div>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                {clubSpeed}
              </div>
              <div style={{ color: "#6C737F" }}>Avg Club Speed</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                {consistencyScore}
              </div>
              <div style={{ color: "#6C737F" }}>Consistency Score</div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ borderBottom: "1px solid #E5E7EB" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="/images/pdf-report/rectangle.svg"
              alt="Custom Background"
              style={{
                width: "25px", // Adjust size as needed
                height: "25px",
                display: "block",
              }}
            />

            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold",
                color: "#333", // Ensure visibility over the SVG
                whiteSpace: "nowrap",
              }}
            >
              01
            </span>
          </div>
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            Swing Type
          </div>
        </div>
        <div
          style={{
            width: "450px",
            textAlign: "start",
            // display: "flex",
            // flexDirection: "column",
            gap: "20px",
            lineHeight: "20px",
            whiteSpace: "pre-wrap",
          }}
          dangerouslySetInnerHTML={{
            __html: descriptionSections[0].template.split("\\n").join("\n"),
            // .split("<b>")
            // .join('<span style="font-weight:bold;">')
            // .split("</b>")
            // .join("</span>"),
          }}
        >
          {/* <div>RC</div>
          <div>
            <span>Movement Type:</span> You are a Spinner because your primary
            movement type is rotational. Your rotational movement ranks at 93th
            percentile compared with the Sportsbox tour database.
          </div>
          <div>
            <span>Speed Source:</span> Your primary speed source is your Core
            because your percent contribution is 18%, which is 0.8 standard
            deviations higher than the tour mean.
          </div> */}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="/images/pdf-report/rectangle.svg"
              alt="Custom Background"
              style={{
                width: "25px",
                height: "25px",
                display: "block",
              }}
            />

            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              02
            </span>
          </div>
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            Speed Score
          </div>
        </div>
        <div
          style={{
            width: "450px",
            textAlign: "start",
            whiteSpace: "pre-wrap",
            lineHeight: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: descriptionSections[1].template.split("\\n").join("\n"),
          }}
        ></div>
      </div>

      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="/images/pdf-report/rectangle.svg"
              alt="Custom Background"
              style={{
                width: "25px",
                height: "25px",
                display: "block",
              }}
            />

            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              03
            </span>
          </div>
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            Efficiency Score
          </div>
        </div>
        <div
          style={{
            width: "450px",
            textAlign: "start",
            whiteSpace: "pre-wrap",
            lineHeight: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: descriptionSections[2].template.split("\\n").join("\n"),
          }}
        ></div>
      </div>

      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="/images/pdf-report/rectangle.svg"
              alt="Custom Background"
              style={{
                width: "25px",
                height: "25px",
                display: "block",
              }}
            />

            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              04
            </span>
          </div>
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            Consistency Score
          </div>
        </div>
        <div
          style={{
            width: "450px",
            textAlign: "start",
            whiteSpace: "pre-wrap",
            lineHeight: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: descriptionSections[3].template.split("\\n").join("\n"),
          }}
        ></div>
      </div>

      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px",
        }}
      >
        <div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="/images/pdf-report/rectangle.svg"
              alt="Custom Background"
              style={{
                width: "25px",
                height: "25px",
                display: "block",
              }}
            />

            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontWeight: "bold",
                color: "#333",
                whiteSpace: "nowrap",
              }}
            >
              05
            </span>
          </div>
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            Areas of Focus
          </div>
        </div>
        <div
          style={{
            width: "450px",
            textAlign: "start",
            whiteSpace: "pre-wrap",
            lineHeight: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: descriptionSections[4].template.split("\\n").join("\n"),
          }}
        ></div>
      </div>

      <div
        style={{
          width: "100%",
          borderBottom: "#E5E7EB 1px solid",
          marginBottom: "20px",
        }}
      ></div>

      <div
        style={{
          marginTop: "auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div>
          Page {1} of {totalPages}
        </div>
      </div>
    </div>
  );
};
