import React from "react";
import { GeneratePdfButton } from "../swing_library/components/PdfGeneration/GeneratePdfButton";

export const PdfWithCSS = () => {
  return (
    <div>
      <GeneratePdfButton
        videoIds={[
         "qlq9SfN1UjdQAY7krvAm", "zn1CahS3AI8M9FmERICX"
        ]}
        golfClub={"D"}
        userName="Jane Doe"
        height="5′11″"
        gender="MALE"
      />
    </div>
  );
};
